import React, {Component} from 'react';
import styled from 'styled-components'
import Layout from "components/Layout/Layout"
import {Container, Row, Col, Collapse} from "reactstrap";
import {graphql, StaticQuery} from "gatsby";
import arrowDown from "images/icons/arrow-down.svg"
import arrowUp from "images/icons/arrow-up.svg"
import HeaderCopy from "components/shared/HeaderCopy"

const FaqSection = styled.div`
    h2 {
        font-family: ${props => props.theme.font.family.light}; 
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
    }
    
    hr {
        border-top: 1px solid ${props => props.theme.colors.superLightGrey};
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    
    a {
        text-decoration: underline;
    }
`

const CollapseBox = styled.div`
    
`

const CollapseHeader = styled.div`
    cursor: pointer;
    position: relative;
    padding: 1.5rem 1.5rem 0.5rem;
    margin: 1rem 0;
    background-color: ${props => props.theme.colors.superLightGrey};
    border: 1px solid ${props => props.theme.colors.grey};
    
    h3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin: 0;
        padding-right: 2rem;
    }
    
    .arrows {
        position: absolute;
        top: 34px;
        right: 1rem;
        width: 23px;
        height: 13px;
    }
    
    .collapse-wrapper {
        margin-top: 1rem;
    }
    
    .collapse {
        padding-bottom: 1rem;
    }
`

const ButtonStyled = styled.button `
    background: transparent;
    border: 0;
    text-align: left;
    outline: none;
    width: 100%;

`


const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                allFaqJson {
                    edges {
                        node {
                            faqName
                            items {
                                title
                                content
                            }
                        }
                    }
                }
            }
		`}
        render={data => (
            <FaqPage data={data}/>
        )}
    />
)

class FaqPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    toggleCollapse = (e) => {
        e.preventDefault();

        const collapse = e.currentTarget.id;
        this.setState({
            [collapse]: !this.state[collapse]
        })

    }

    getFaqElements = (items, key) => {
        return items.map((item, i) => {
            return (
                <Col key={`${key}-${i}`} xs={12} lg={6}>
                    <CollapseBox>
                        <CollapseHeader
                             className={`${this.state[`collapse-${key}-${i}`] !== true ? 'active' : ''}`}>
                            <ButtonStyled id={`collapse-${key}-${i}`} onClick={this.toggleCollapse} style={{ marginBottom: '1rem' }}>
                                <h3>{item.title}</h3>
                                {this.state[`collapse-${key}-${i}`] === true ?
                                    <img className="arrows" src={arrowUp} alt="" />
                                    :
                                    <img className="arrows" src={arrowDown} alt="" />
                                }
                            </ButtonStyled>

                            {/* <Relative id={`collapse-${key}-${i}`} onClick={this.toggleCollapse}>
                                <h3>{item.title}</h3>
                                {this.state[`collapse-${key}-${i}`] === true ?
                                    <img className="arrows" src={arrowUp} alt="" />
                                :
                                    <img className="arrows" src={arrowDown} alt="" />
                                }
                            </Relative> */}

                            <div className="collapse-wrapper">
                                <Collapse isOpen={this.state[`collapse-${key}-${i}`]}>
                                    <div dangerouslySetInnerHTML={{__html: item.content}}/>
                                </Collapse>
                            </div>
                        </CollapseHeader>
                    </CollapseBox>
                </Col>
            )
        })
    }

    listFaqs = () => {
        return this.props.data.allFaqJson.edges.map(({node}, i) => {
            return (
                <FaqSection key={i}>
                    <h2>{node.faqName}</h2>
                    <Row>
                        {this.getFaqElements(node.items, i)}
                    </Row>
                    <hr/>
                </FaqSection>
            )
        })
    }

    render() {
        return (
            <Layout slug="faq" title="FAQs">
                <HeaderCopy title="FAQs"/>
                <Container fluid={true} className="my-4">
                    <Container>
                        {this.listFaqs()}
                    </Container>
                </Container>
            </Layout>
        );
    }
}

export default Query;